import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage'; 
const config = {
    apiKey:process.env.REACT_APP_API_KEY_PROD,
    authDomain:process.env.REACT_APP_AUTH_DOMAIN_PROD,
    databaseURL:process.env.REACT_APP_DATABASE_URL_PROD,
    projectId:process.env.REACT_APP_PROJECT_ID_PROD,
    storageBucket:process.env.REACT_APP_STORAGE_BUCKET_PROD,
    messagingSenderId:process.env.REACT_APP_MESSAGING_SENDER_ID_PROD,
    appId:process.env.REACT_APP_APP_ID_PROD,
    measurementId:process.env.REACT_APP_MEASUREMENT_ID_PROD
  };
firebase.initializeApp(config);
var storageRef = firebase.storage().ref();
var storage = firebase.storage();
export default storageRef;


