import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import { Switch } from 'react-router';
import SearchField from './Components/SearchField';
import SignIn from './Components/SignIn';
import Authorization from './Components/Authorization'
const Routes = () => {
    return(
        <Router>
            <Switch>
            <Authorization path="/" component={SearchField} exact={true} />
            <Route path="/signIn" component={SignIn} exact={true} />
            </Switch>
        </Router>
    );
    }
export { Routes }


