import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage';
import './ImageView.css';
import storageRef from '../Firestore';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import BottomScrollListener from 'react-bottom-scroll-listener';
import DateFnsUtils from '@date-io/date-fns';
import Spinner from 'react-bootstrap/Spinner';
import TextField from '@material-ui/core/TextField';
import computeFilter from '../lib/search';
import activityLog from '../firebaseDB';
import ActivityTypes from '../lib/constants'
const algoliasearch = require('algoliasearch');
const client = algoliasearch(process.env.REACT_APP_APPLICATION_ID_PROD, process.env.REACT_APP_SEARCH_ONLY_API_KEY_PROD);
const index = client.initIndex('hfn-search-event-info');
const event_type = [
  { label: "group-meditation" },
  { label: "Yoga" },
  { label: "at-work" },
  { label: "s-connect" },
  { label: "u-connect" },
  { label: "v-connect" },
  { label: "dhyanotsav" },
  { label: "g-connect" },
  { label: "Heartfulness Introduction" },
  { label: "Brighter-Minds" },
  { label: "CME" },
  { label: "CSR" },
  { label: "Divya-Janani" },
  { label: "Family-Connect" },
  { label: "Heartfulness-green" },
  { label: "Indian Diaspora" },
  { label: "Kaushalam" },
  { label: "L-Connect" },
  { label: "NGO-Connect" },
  { label: "ROCF" },
  { label: "Religious-Institutions-connect" },
  { label: "Research" },
  { label: "Youth" },
  { label: "glow-pearl" },
  { label: "books-and-more" },
  { label: "Other" }
]
class SearchField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { searchData: {}, from_date_of_event: null, to_date_of_event: null, details: [], loading: false, fileUrls: [], searchRequest: false, queryOnFilter: '', activePage: 0, numberOfHits: 0 };
    this.handleChange = this.handleChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }
  componentDidMount() {
    storageRef.child(`images/1.jpg`).getDownloadURL().then((url) => {
    })
  }
  handleDateChange = (date, name) => {
    const toDate = new Date(date);
    this.setState({ [name]: date })
  };
  handleChange(e) {
    e.preventDefault();
    const { searchData } = this.state
    const { name, value } = e.target;
    searchData[name] = value;
    this.setState({ searchData });
  }
  toDataURL = (url) => {
    return fetch(url).then((response) => {
      return response.blob();
    }).then(blob => {
      return URL.createObjectURL(blob);
    });
  }
  download = async (url) => {
    const a = document.createElement("a");
    a.href = await this.toDataURL(url);
    var mySubString = url.substring(
      url.lastIndexOf("/") + 1,
      url.lastIndexOf("?")
    );
    a.download = mySubString;
    document.body.appendChild(a);
    a.click();
    activityLog(url,ActivityTypes.download);
    document.body.removeChild(a);
  }
  onUpdateFileUrls = () => {
    const{searchData,from_date_of_event,to_date_of_event}=this.state;
    const { activePage } = this.state;
    index.search({
      query: '',
      page: activePage,
      hitsPerPage: 5,
      attributesToRetrieve: ['event_id', 'event_type', 'event_day', 'date_of_event', 'count', 'country', 'institution', 'city', 'coordinator_name', 'coordinator_phone', 'coordinator_email', 'trainer_id', 'feedback', 'file', 'date_timestamp', 'file_count'],
      restrictSearchableAttributes: ['city', 'institution', 'event_type', 'country', 'date_of_event', 'date_timestamp', 'file_count'],
      filters: `(${computeFilter(searchData, from_date_of_event, to_date_of_event)})`
    }).then((res) => {
      const filtered = res.hits
      let { fileUrls } = this.state;
      fileUrls = [...fileUrls, ...filtered]
      const filteredArraySize = filtered.length;
      this.setState({
        fileUrls,
        loading: false,
        numberOfHits: res.nbHits,
        searchRequest:true
      })
    })
  }
  onSearch = () => {
    const { searchData, from_date_of_event, to_date_of_event } = this.state;
    if(!searchData.city && !searchData.institution && !searchData.country && !searchData.event_type && !from_date_of_event && !to_date_of_event){
      alert('Please enter the details to continue searching.')
      return;
    }
    if (!searchData.event_type) {
      alert('Please fill event type')
      return
    }
    if ((from_date_of_event && !to_date_of_event) || (!from_date_of_event && to_date_of_event)) {
      alert("Please enter the dates")
      return;
    }
    if(from_date_of_event > to_date_of_event){
      alert("Please enter the dates in format.(To > From)")
      return;
    }
    if (searchData.city || searchData.institution || searchData.country || searchData.event_type || from_date_of_event || to_date_of_event) {
      const { details, loading, activePage } = this.state;
      let activityData = {...searchData, from: from_date_of_event, to: to_date_of_event  }
      activityLog(activityData,ActivityTypes.search);
      this.state.fileUrls = []
      this.state.activePage = 0;
      this.setState({
        details: [],
        loading: true,
      })
      this.onUpdateFileUrls()
    }
  }
  handlePageChange = (pageNumber) => {
    this.setState({
      activePage: pageNumber
    }, () => this.onSearch())


  }
  getKeyFromFile = (file) => {
    const result = Object.keys(file).map((key) => {
      return key;
    })
    return result[0];
  }
  getEventTypeOptions = () => {
    return event_type.map((item) => {
      return <MenuItem value={item.label}>{item.label}</MenuItem>

    })

  }
  handleScroll = e => {
    const el = e.target.documentElement
    const bottom = el.scrollHeight - el.scrollTop === el.clientHeight
    if (bottom) {
      const { activePage, fileUrls } = this.state;
      const pageNumber = activePage + 1;
      this.setState({
        activePage: pageNumber
      }, () => this.onSearch())
    }
  }
  handleOnDocumentBottom = e => {
    const { activePage, fileUrls } = this.state;
    const pageNumber = activePage + 1;
    this.setState({
      activePage: pageNumber
    }, () => this.onUpdateFileUrls())
  }

  onLoadMore = () => {
    const { activePage, fileUrls } = this.state;
    const pageNumber = activePage + 1;
    this.setState({
      activePage: pageNumber
    }, () => this.onUpdateFileUrls())
  }
  signOutFunction = () => {
    
      firebase.auth().signOut().then(function() {
        // Sign-out successful.
        activityLog(null,ActivityTypes.logOut);
      }).catch(function(error) {
        // An error happened.
      });
  
  }

  render() {
    const { loading } = this.state;
    return (
      <React.Fragment>
        <div className="background-image">
          <div className="sign-out">
            <a href="#" className="sign-out-button" onClick={this.signOutFunction}>Sign out</a>
            </div>
          <div className="search-component">
            <h1 className="page-heading">Search Photos from Heartfulness Events</h1>
            <div className="search-sections">
              <div className="search-bar">
                <div className="label-tag-value"><label className="label-tags">Event type<span className="asterisks">*</span></label></div>
                <div className="input-box-value">
                  <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={this.state.searchData.event_type} onChange={this.handleChange} placeholder="Select.." name="event_type" >{this.getEventTypeOptions()}</Select>
                </div>
              </div>
              <div className="search-bar from-to-date-picker-small-screen">
                <div className="label-tag-value">
                  <label className="label-tags">From</label>
                </div>
                <div className="input-box-value">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      id="date-picker-dialog"
                      format="yyyy-MM-dd"
                      value={this.state.from_date_of_event}
                      placeholder="Select date..."
                      onChange={(date) => this.handleDateChange(date, 'from_date_of_event')}
                    /></MuiPickersUtilsProvider>
                  <div className="label-tag-value">
                    <label className="to-label-tags">To</label>
                  </div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      id="date-picker-dialog"
                      format="yyyy-MM-dd"
                      value={this.state.to_date_of_event}
                      placeholder="Select date..."
                      onChange={(date) => this.handleDateChange(date, 'to_date_of_event')}
                    /></MuiPickersUtilsProvider>
                </div>
              </div>
              <div className="search-bar from-to-date-picker-big-screen">
                <div className="label-tag-value"><label className="label-tags">From</label></div>
                <div className="input-box-value">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      id="date-picker-dialog"
                      format="yyyy-MM-dd"
                      value={this.state.from_date_of_event}
                      placeholder="Select date..."
                      onChange={(date) => this.handleDateChange(date, 'from_date_of_event')}
                    /></MuiPickersUtilsProvider>
                </div>
              </div>
              <div className="search-bar from-to-date-picker-big-screen">
                <div className="label-tag-value">
                  <label className="to-label-tags">To</label>
                </div>
                <div className="input-box-value">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      id="date-picker-dialog"
                      format="yyyy-MM-dd"
                      value={this.state.to_date_of_event}
                      placeholder="Select date..."
                      onChange={(date) => this.handleDateChange(date, 'to_date_of_event')}
                    /></MuiPickersUtilsProvider>
                </div>
              </div>
              <div className="search-bar">
                <div className="label-tag-value"><label className="label-tags">Institution</label></div>
                <div className="input-box-value"><TextField id="outlined-basic" placeholder="Search..." variant="filled" value={this.state.searchData.institution} onChange={this.handleChange} className="search-text" name="institution" /></div>
              </div>
              <div className="search-bar">
                <div className="label-tag-value"><label className="label-tags">City</label></div>
                <div className="input-box-value"><TextField id="outlined-basic" placeholder="Search..." variant="filled" value={this.state.searchData.city} onChange={this.handleChange} className="search-text" name="city" /></div>
              </div>
              <div className="search-bar">
                <div className="label-tag-value"><label className="label-tags">Country</label></div>
                <div className="input-box-value"><TextField id="outlined-basic" placeholder="Search..." variant="filled" value={this.state.searchData.country} onChange={this.handleChange} className="search-text" name="country" /></div>
              </div>
            </div>
            <button type="submit" className="search-button" onClick={this.onSearch}>Search</button>
          </div>
        </div>
        <div className="loader">
          {
            loading ? <div >
              <Spinner animation="border" variant="secondary" size="md" className="loader-size" />
            </div> :
              <div className="image-wrapper" >
                { (this.state.fileUrls.length > 0 || !this.state.searchRequest) ? 
                <React.Fragment>
                  {this.state.fileUrls.map((item, number) => {
                  return (
                    <React.Fragment>
                      {
                        item.file && item.file.map((file) => {
                          const key = this.getKeyFromFile(file);
                          const date = item.date_of_event.split("T")[0].split("-").reverse().join("-")
                          return (
                            <div className="info-wrapper">
                              <div className="file-img " style={{ background: `url(${file[key].fileUrl})` }} />
                              <div className="text">
                                <div className="wrapper-download-tag">
                                  <div className="metadata-text">Date of event: {date}</div>
                                  <div className="metadata-text">Event type:{item.event_type.label ? item.event_type.label : item.event_type}</div>
                                  <div className="metadata-text">Institution:{item.institution}</div>
                                  <div className="metadata-text">City:{item.city}</div>
                                  <div className="metadata-text">Country:{item.country.label ? item.country.label : item.country}</div>
                                </div>
                                <div className="download-tag" onClick={() => this.download(file[key].fileUrl)}>
                                  <FontAwesomeIcon icon={faDownload} className="buttonstyle1" />
                                </div>
                              </div>
                            </div>
                          )
                        })
                }
                      
                    </React.Fragment>
                  )
                })
                }
                  <BottomScrollListener onBottom={this.handleOnDocumentBottom} />
                </React.Fragment>
                  :
                  <h1 className="error-message">No images found!</h1>
                }

              </div>
          }
        </div>
      </React.Fragment>
    );
  }
}
export default SearchField;