import * as React from 'react';
import {Route} from 'react-router-dom';
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage'; 
import * as firebase from "firebase/app"
import Spinner from 'react-bootstrap/Spinner';
import { withRouter } from "react-router";
   class Authorization extends React.Component {
    constructor(props) {
      super(props);
      this.state = { signedIn: false }
    }
    componentDidMount() {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          this.setState({ signedIn: true })
        } else {
          this.props.history.push('/signIn');
        }
      });
    }
    render() {
      const { component: Component, ...rest } = this.props;
      const { signedIn } = this.state;
      return (
        <React.Fragment>
          <div>{
            !signedIn ?
              <div>
               <Spinner animation="border" />
              </div> :
              <Route
              {...rest}
              render={props => (<Component {...props} />)}
          />
          }
          </div>
        </React.Fragment>
      )
    }
  }
export default withRouter(Authorization)
