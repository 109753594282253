const firebase = require("firebase/app");
const ref = firebase.firestore().collection('prod_hfn_activity_log');
const user_emailID= localStorage.getItem("user_email_id");
const user_ID= localStorage.getItem("user_ID");
const activityLog = (data,activityType) => {
    ref.add({
        id:user_ID,
        email :user_emailID,
        activityType:activityType,
        timestamp:new Date(),
        data:data
    })
    .then(function(docRef) {
        console.log("Document written with ID: ", docRef.id);
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    });
} 
export default activityLog;