import * as React from 'react';
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage';
import * as firebase from "firebase/app"
import './SignIn.css'
import { createBrowserHistory } from 'history'
import Spinner from 'react-bootstrap/Spinner';
import activityLog from '../firebaseDB';
import ActivityTypes from '../lib/constants'
const history=createBrowserHistory();
class SignIn extends React.Component {
provider = new firebase.auth.GoogleAuthProvider();
componentDidMount(){
    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          localStorage.setItem('user_email_id',user.email)
          localStorage.setItem('user_ID',user.uid)
          activityLog(null,ActivityTypes.logIn);
          localStorage.setItem('loader_sign_in', false);
          this.props.history.push('/')
        } else {
          localStorage.setItem('loader_sign_in', false);
          this.props.history.push('/signIn');
          
        }
      });
}
 LoginButton = (e) =>{
    localStorage.setItem('loader_sign_in', true);
    firebase.auth().signInWithRedirect(this.provider).then(function(result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        // ...
      }).catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
}

    render() {
       var loading= localStorage.getItem("loader_sign_in");
        return (
            <div >
            {loading ==='true' ?
            <div >
            <Spinner animation="border" variant="secondary" size="md" className="signInLoader"/>
            </div> :
            <React.Fragment>
                <div className="image-title">
                <img src="hfn-event-hero.png" className="photo"></img>
                <div className="centered">
                    <h1 className="form-title">Search Heartfulness Event Reports</h1>
                    <div className="sign-in-components">
                        <button className="login-button" onClick={this.LoginButton}>Sign In with Google</button>
                    </div>
                </div>
            </div>
            </React.Fragment>
    }
            </div>
            
        )
    }
}
export default SignIn;